.wrapper {
	min-height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	background-color: $white;
}

.container {
	width: 100%;
	max-width: $max-width;
	padding: 0 30px;
	margin: 0 auto;
	position: relative;
}

main {
	flex: 1 0 auto;
	overflow: hidden;
	margin-top: 130px;
	@include breakpoint(md) {
		margin-top: 80px;
	}
}
