.intro {
	position: relative;
	z-index: 5;
	padding: 100px 0 25px;
	background-color: $dark-blue;
	min-height: calc(100vh - 130px);
	height: 100%;
	display: flex;
	align-items: flex-end;
	@include breakpoint(xxl) {
		padding: 100px 0;
		min-height: calc(60vh - 130px);
	}
	@include breakpoint(md) {
		padding: 85px 0 10px;
		min-height: calc(60vh - 90px);
	}

	&:before {
		content: "";
		position: absolute;
		width: 120%;
		height: 150%;
		background-color: $white;
		top: -90%;
		left: 58%;
		transform: translateX(-50%);
		border-bottom-left-radius: 50%;
		border-bottom-right-radius: 50%;
		@include breakpoint(md) {
			width: 130%;
			height: 70%;
			top: -37%;
		}
	}


	&-holder {
		display: flex;
		flex-wrap: wrap;
		@include breakpoint(md) {
			flex-direction: column-reverse;
		}
	}
	&-text {
		max-width: 610px;
		color: $white;
		@include breakpoint(lg) {
			max-width: 400px;
		}
		@include breakpoint(md) {
			margin-top: -28px;
			position: relative;
			z-index: 2;
		}
		.title {
			margin-bottom: 35px;
			padding-bottom: 50px;
			&:after {
				@include breakpoint(md) {
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
		p {
			font-size: 35px;
			letter-spacing: .05em;
			@include breakpoint(lg) {
				font-size: 18px;
			}
			@include breakpoint(md) {
				text-align: center;
			}
		}
	}

	&-image {
		width: 50vw;
		height: 50vw;
		position: absolute;
		left: calc(50vw - 50px);
		top: 50%;
		transform: translateY(-50%);
		@include breakpoint(xxl) {
			left: calc(50vw + 150px);
		}
		@include breakpoint(xl) {
			left: calc(50vw + 50px);
		}
		@include breakpoint(md) {
			position: relative;
			top: 0;
			left: 0;
			transform: none;
			width: 85vw;
			height: 85vw;
			margin: 0 -90px 0 auto;
		}
	}

}

.circle-box {
	position: relative;
	border-radius: 50%;
	overflow: hidden;
	width: 100%;
	height: 100%;
	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
