.contact {
	position: relative;
	padding: 270px 0 200px;
	background-color: $dark-blue;
	@include breakpoint(md) {
		background-color: $white;
		padding: 190px 0 70px;
	}

	&:before {
		content: "";
		position: absolute;
		width: 120%;
		height: 200%;
		background-color: $white;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		border-top-left-radius: 50%;
		border-top-right-radius: 50%;
		@include breakpoint(xxl) {
			height: 100%;
		}

		@include breakpoint(md) {
			width: 200%;
			top: -93%;
			background-color: $dark-blue;
			border-bottom-left-radius: 50%;
			border-bottom-right-radius: 50%;
		}
	}
	&-holder {
		max-width: 570px;
		margin: 0 auto;
		text-align: center;

		.title {
			margin-bottom: 50px;
			@include breakpoint(md) {
				margin-bottom: 27px;
				padding: 0 0 32px 0;
			}
			&:after {
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	&-info {
		display: flex;
		justify-content: space-between;
		max-width: 490px;
		margin: 60px auto 0;
		@include breakpoint(md) {
			flex-direction: column-reverse;
			margin: 30px auto 0;
			justify-content: flex-start;
		}
		.contact-card {
			@include breakpoint(md) {
				display: flex;
				align-items: center;
				background-color: $red;
				border-radius: 43px;
				box-shadow: 0 1px 0 rgba(0,0,0,.10);
				padding: 10px 20px;
				margin-bottom: 23px;
				span {
					background-color: transparent;
					width: 30px;
					height: 30px;
					margin: 0;
					border-radius: 0;
					box-shadow: none;

				}
				a {
					color: $white;
					text-align: center;
					margin: 0 auto;
				}
				p {
					position: absolute;
					bottom: 5px;
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}
}

.contact-card {
	text-align: center;
	span {
		background-color: $red;
		position: relative;
		color: $white;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 62px;
		height: 62px;
		border-radius: 50%;
		font-size: 30px;
		box-shadow: 0 3px 6px rgba(0,0,0,.16);
		margin: 0 auto 15px;
		&.icon-envelope {
			font-size: 20px;
		}
	}
	a {
		font-weight: 600;
	}
	p {
		font-size: 10px;
	}
}
