$grid-breakpoints: (
    xxs: 360px,
    xs: 480px,
    sm: 640px,
    md: 769px,
    lg: 1024px,
    xl: 1200px,
    xxl: 1440px,
    xxxl: 1920px
);

@mixin breakpoint($size, $rule: max-width) {
    @media (#{$rule}: map-get($grid-breakpoints, $size) - 1px) {
        @content;
    }
}

@mixin breakpoint-mf($size, $rule: min-width) {
    @media (#{$rule}: map-get($grid-breakpoints, $size) ) {
        @content;
    }
}

@mixin breakpoint-between($min-size, $max-size) {
    $min: map-get($grid-breakpoints, $min-size);
    $max: map-get($grid-breakpoints, $max-size) - 1px;

    @media (min-width: $min) and (max-width: $max) {
        @content;
    }
}
