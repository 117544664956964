@import 'vendors/normalize';
@import 'vendors/icomoon';

@import 'utils/variables';
@import 'utils/grid';
@import 'utils/breakpoints';

@import 'base/font';
@import 'base/reset';
@import 'base/typography';
@import 'base/helpers';

@import 'components/common';
@import 'components/header';
@import 'components/footer';

@import 'blocks/intro';
@import 'blocks/contact';


/**
 *  Reset defaults
 */

* {
	box-sizing: border-box;
}

html, body {
	margin: 0;
	padding: 0;
}
