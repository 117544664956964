.footer {
	position: relative;
	background-color: $white;

	.container {
		max-width: 1360px;
	}

	&-top {
		position: relative;
		padding: 190px 0 100px;
		background-color: $white;
		color: $white;
		@include breakpoint(md) {
			padding: 116px 0 56px;
		}
		&:before {
			content: "";
			position: absolute;
			width: 114%;
			height: 100%;
			background-color: $dark-blue;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			border-top-left-radius: 50%;
			border-top-right-radius: 50%;
			@include breakpoint(md) {
				width: 160%;
			}
		}

		.row {
			justify-content: space-between;
			align-items: center;
			@include breakpoint(md) {
				flex-direction: column-reverse;
			}
		}

	}
	&-address {
		font-size: 14px;
		max-width: 300px;
		@include breakpoint(md) {
			text-align: center;
			margin: 0 auto;
		}
		p {
			font-weight: 600;
		}
	}

	&-contact {
		display: flex;
		width: 100%;
		justify-content: space-between;
		padding-left: 50px;
		@include breakpoint(md) {
			padding-left: 0;
			flex-direction: column-reverse;
		}

	.contact-card {
		@include breakpoint(md) {
			margin-bottom: 50px;
		}
			a {
				color: $white;
				&:hover {
					color: $blue;
				}
			}
		}
	}

	&-copy {
		padding: 28px 0;
		text-align: center;
		@include breakpoint(md) {
			padding: 20px 0;
		}
		.container {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			max-width: 100%;
		}
		&-holder {
			p {
				font-size: 14px;
			}

			.logo {
				margin-top: 20px;
				img {
					height: 37px;
					@include breakpoint(sm) {
						height: 37px;
					}
				}
			}
		}
	}
}
