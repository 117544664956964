@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.ttf?hpgffl') format('truetype'),
    url('../fonts/Poppins/Poppins-Regular.woff?hpgffl') format('woff'),
    url('../fonts/Poppins/Poppins-Regular.woff2?hpgffl') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

//@font-face {
//    font-family: 'Poppins';
//    src: url('../fonts/Poppins/Poppins-Light.otf?hpgffl') format('truetype'),
//    url('../fonts/Poppins/Poppins-Light.woff?hpgffl') format('woff'),
//    url('../fonts/Poppins/Poppins-Light.woff2?hpgffl') format('woff2');
//    font-weight: 300;
//    font-style: normal;
//    font-display: block;
//}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Bold.ttf?hpgffl') format('truetype'),
    url('../fonts/Poppins/Poppins-Bold.woff?hpgffl') format('woff'),
    url('../fonts/Poppins/Poppins-Bold.woff2?hpgffl') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Righteous';
		src: url('../fonts/Righteous/Righteous-Regular.ttf?hpgffl') format('truetype'),
		url('../fonts/Righteous/Righteous-Regular.woff?hpgffl') format('woff'),
		url('../fonts/Righteous/Righteous-Regular.woff2?hpgffl') format('woff2');
   	font-display: auto;
    font-style: normal;
    font-weight: 400;
}
