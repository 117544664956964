$max-width: 1640px;

$white: #fff;

$dark-blue: #00364E;
$blue: #1B8FC1;
$red: #FA6B55;

$link-color: $dark-blue;

$base-background: $white;
$base-color: $dark-blue;
$base-font-family: 'Poppins', sans-serif;
$title-font-family: 'Righteous', sans-serif;
$base-font-size: 18px;
$base-font-size-tablet: 16px;
$base-font-size-mobile: 14px;

$base-line-height: 1.2;
$base-font-weight: 400;

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
