.header {
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 15;
	background: $white;
	height: 130px;
	padding: 10px 0;
	box-shadow: 0 1px 3px rgba(0,0,0,.05);
	display: flex;
	align-items: center;

	@include breakpoint(md) {
		height: 90px;
		padding: 20px 0;
		justify-content: center;
	}

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include breakpoint(md) {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.logo {
	display: inline-block;
	flex-shrink: 0;
	transition: none;
	z-index: 12;
	img {
		display: block;
		height: 81px;
		@include breakpoint(sm) {
			height: 40px;
		}
	}
}

