html {
    height: 100%;
    -webkit-tap-highlight-color: transparent;
}

body {
    background: $base-background;
    color: $base-color;
    font-size: $base-font-size;
    font-family: $base-font-family;
    line-height: $base-line-height;
    font-weight: $base-font-weight;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    @include breakpoint(xxl) {
        font-size: $base-font-size-tablet;
    }

    @include breakpoint(lg) {
        font-size: $base-font-size-mobile;
    }
}

*,
:after,
:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

p {
    line-height: 1.4;
    margin-bottom: 15px;

    &:last-of-type {
        margin-bottom: 0 !important;
    }

    a {
        text-decoration: underline;
        text-decoration-thickness: 1px;

        &:hover {
            text-decoration: none;
        }
    }
}

a:not([class]) {
    color: $link-color;
    text-decoration: none;
    transition: all 0.3s;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    &:hover {
			color: $blue;

    }
}

b,
strong {
    font-weight: 700;
}

i,
em {
    font-style: italic;
}

img {
    vertical-align: top;
    max-width: 100%;
    max-height: 100%;
    height: auto;
}

input,
button {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $title-font-family;
    line-height: 1;
    font-weight: normal;
    margin: 0 0 12px;
    position: relative;
    text-transform: lowercase;
		color: $blue;

    &:last-child {
        margin-bottom: 0 !important;
    }

    a {
        color: inherit;
    }
}

h1 {
    font-size: 200px;
    line-height: .8;

    @include breakpoint(xxl) {
        font-size: 150px;
    }
		@include breakpoint(lg) {
			font-size: 85px;
		}
}

h2 {
    font-size: 60px;
    @include breakpoint(lg) {
        font-size: 40px;
    }
}

h3 {
    font-size: 80px;
    @include breakpoint(lg) {
        font-size: 50px;
    }
}

h4 {
    font-size: 24px;
    font-weight: 600;
    font-family: $base-font-family;
}

h5 {
    font-size: 18px;
    font-weight: 600;
    font-family: $base-font-family;
}

h6 {
    font-size: 16px;
}

.title {
	position: relative;
	padding: 0 0 65px 0;
	text-shadow: 1px 3px 6px rgba(0,0,0,.35);
	display: inline-block;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 90%;
		height: 3px;
		background-color: $red;
		@include breakpoint(md) {
			width: 60%;
		}
	}
	span {
		font-family: $base-font-family;
		font-weight: 600;
	}
}



